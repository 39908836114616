
import { defineComponent, PropType } from 'vue';
import { ImagePreview } from 'vant';

export default defineComponent({
  name: 'MomaiImg',
  props: {
    imgs: {
      type: Array as PropType<string[]>,
      default: []
    }
  },
  setup(props) {
    const preview = (index: number) => {
      ImagePreview({
        images: props.imgs,
        startPosition: index,
        showIndicators: true,
      });
    }
    return {
      preview
    }
  }
})
